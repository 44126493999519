import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import rehypeReact from "rehype-react"
import { Container, Typography, Box, Paper, Link } from "@mui/material"

export default function Template({
  data,
  children, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, htmlAst } = markdownRemark

  const para = ({ children }) => (
    <Typography sx={{ paddingBottom: "0.25em" }} variant="body1">
      {children}
    </Typography>
  )

  const heading1 = ({ children }) => (
    <Typography sx={{ fontSize: { xs: "3rem" } }} variant="h1">
      {children}
    </Typography>
  )

  const heading2 = ({ children }) => (
    <Typography sx={{ fontSize: { xs: "2rem" } }} variant="h2">
      {children}
    </Typography>
  )

  const heading3 = ({ children }) => (
    <Typography sx={{ fontSize: { xs: "2rem" } }} variant="h3">
      {children}
    </Typography>
  )

  const heading4 = ({ children }) => (
    <Typography variant="h4">{children}</Typography>
  )
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h1: heading1,
      h2: heading2,
      h3: heading3,
      h4: heading4,
      p: para,
    },
  }).Compiler
  return (
    <Paper
      sx={{
        "&.MuiPaper-root": {
          color: "white",
          background: `linear-gradient(to bottom right, #A9BED9, #8498BF, #2C3673)`,
        },
      }}
    >
      <Container
        maxWidth="false"
        sx={{
          borderBottom: "0.75em solid primary.accentDark",
          maxWidth: "90%",
          marginBottom: 0,
        }}
        className="blog-post-container"
      >
        {/* <div className="blog-post"> */}
        <Typography
          sx={{ textAlign: "center", color: "primary.main" }}
          variant="h1"
        >
          {frontmatter.title}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            paddingBottom: "1.5em",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GatsbyImage
            image={frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          ></GatsbyImage>
          <Link href={frontmatter.attributionURL}>
            <Typography
              variant="body2"
              sx={{
                paddingTop: "0.75em",
                color: "primary.main",
                "&.MuiTypography-body2": { fontSize: "0.5em" },
              }}
            >
              {frontmatter.attributionText}
            </Typography>
          </Link>
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: "primary.main",
            "&.MuiTypography-body2": { fontWeight: "700" },
          }}
        >
          {"By " + frontmatter.author + " on " + frontmatter.date}
        </Typography>

        {renderAst(htmlAst)}
        {/* </div> */}
      </Container>
    </Paper>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        slug
        title
        author
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        attributionText
        attributionURL
      }
    }
  }
`
